@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;900&display=swap");

.head11 {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 900;
  font-size: 52.91px;
  line-height: 53px;
  color: white;
  letter-spacing: 0.1em;
}
.head1 {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 90px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 38px;
  letter-spacing: 0.05em;
}
.head2 {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 50px;
  color: white;
}
.herobg {
  /* background-image: url(./images/hero.png);
  background-size: cover;
  background-position: center; */
}
.herobg .row {
  min-height: 100vh;
}
.arrow {
  height: 40px;
}
.text {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  font-size: 18.66px;
  line-height: 22px;
  color: #fffc;
  text-decoration: none;
}
.scroll {
  letter-spacing: 0.4em;
}
.aboutbg {
  background-image: url(./images/about_bg.png);
  background-size: cover;
  background-position: bottom center;
}
@media screen and (min-width: 576px) {
  .aboutbg .row {
    /* padding-top: 167px !important;
    padding-bottom: 167px !important; */
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-left {
    text-align: left !important;
  }
}
.row.full {
  /* padding-top: 100px; */
  /* padding-bottom: 167px; */
  min-height: 100vh;
}
.space {
  height: 180px;
}
body {
  background-color: #07121a;
}
.avatarbg .row.full {
  min-height: 100vh;
}
.enterbg,
.blueprintbg,
.upsidebg,
.tunnelbg,
.teambg,
.faqbg,
.footerbg {
  background-color: #07121a;
}
.blueprintbg .row,
.upsidebg .row,
.tunnelbg .row,
.teambg .row.full,
.faqbg .row {
  min-height: 100vh;
  /* padding: 150px 0 0; */
}
.icon {
  height: 55px;
}
.enterbg-2 {
  background-image: url(./images/enter.png);
  background-size: cover;
  background-position: center;
  mix-blend-mode: lighten;
  min-height: 50vh;
}
.btn-theme {
  padding: 10px 16px;
  color: white;
  background-color: #1d2652;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.imgfilter {
  mix-blend-mode: lighten;
}
.box {
  background-color: #182030;
  margin-top: 50%;
}
/* .dronbig {
  top: -107%;
  bottom: 107%;
  height: 415px;
  width: 359px;
  object-fit: cover;
  left: 4vw;
  position: relative;
  max-width: 100%;
} */
.sign {
  max-width: 100%;
}
.faqbg {
  background-image: url(./images/faq.png);
  background-size: cover;
  background-position: center;
}
.accordion-item,
.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-item {
  border: 0;
  border-bottom: 1px solid #fffc;
}
.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.footer2bg {
  background-color: #0a141e;
}
.head1.mid {
  font-size: 50px;
}
.footlogo {
  width: 81px;
  height: 81px;
}
.text-right {
  text-align: right;
}
.border {
  border: none !important;
  border-bottom: 1px solid #363f6b !important;
}
.nav-link {
  font-size: 18px;
  letter-spacing: 0.09em;
  /* padding: 32px 27px !important; */
  color: #fffc !important;
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.2em;
}
.brick {
  max-width: 100%;
  width: 100%;
  /* padding-top: 150px; */
  margin-top: -15%;
  /* z-index: 99; */
  position: relative;
}
.dots {
  max-width: 100%;
}
.character {
  position: relative;
  width: 100%;
  height: 390px;
  overflow: hidden;
  z-index: 99;
}
.character_img {
  position: absolute;
  object-fit: contain;
  height: 780px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* body,
#root {
  height: 100vh;
  overflow: hidden;
}
.main {
  overflow: auto;
  height: 100vh;
} */
.scrollFix {
  /* scroll-snap-type: y mandatory;
  overflow: auto;
  height: 100vh; */
}
#blueprint,
#downside,
#tunnel,
#upside {
  /* display: inline-block;
  flex: 0 0 auto; */
  /* scroll-snap-align: center;
  height: 100vh !important; */
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.2s ease-in-out;
  z-index: 9999;
}
.nav-up {
  top: -87px;
}
/* .buttons img:hover {
  background-color: #363f6b;
} */

.button_color {
  width: 24px;
  height: 20px;
  margin: auto;
  margin-bottom: 3px;
}
.button_color.yellow {
  background-color: #ffc08b;
}
.button_color.tan {
  background-color: #ffa26e;
}
.button_color.brown {
  background-color: #c1633e;
}
.button_wrap_color {
  text-align: center;
  background-color: #182030;
  padding: 14px 14px 7px;
}
/* .button_wrap_color:hover {
  background-color: #1d2652;
  cursor: pointer;
} */
.button_wrap_color .text {
  font-size: 12px;
}
.buttonimg {
  height: 24px;
  object-fit: contain;
  margin: 10px 0 14px;
}
.icons_btns img {
  width: 20px;
  height: 20px;
  border-right: 1px solid rgba(89, 101, 158, 0.2);
  padding-right: 5px;
  margin-right: 5px;
  object-fit: contain;
}
.icons_btns {
  color: white;
  font-size: 12px;
}
.icons_btns .button_wrap_color {
  text-align: left;
  padding: 10px 0 10px 15px;
}
.foothead {
  font-weight: 400;
  font-size: 31.4607px;
  line-height: 31px;
}
.teambox {
  width: 235px;
  height: 264px;
}
.teambox img {
  width: 195px;
  height: 195px;
  object-fit: cover;
}
.teambox:hover,
.focused {
  background-color: #182030;
}
.borders {
  border-top: 1px solid #394867;
  border-bottom: 1px solid #394867;
}
.slide {
  min-height: 100vh;
}
.arrow {
  position: absolute;
  width: 30px;
  object-fit: contain;
  top: 50%;
  left: 10px;
  z-index: 999;
  cursor: pointer;
}
.arrow.right {
  left: auto;
  right: 10px;
}
#slides {
  position: relative;
}
@media (min-width: 1921px) {
  .d-xlc-block {
    display: block !important;
  }
}
/* @media (min-height: )
.avatarfix{

} */
.cityhall {
  line-height: 68px;
}
#enter .img1 {
  width: 66.69px;
  height: 34.68px;
  object-fit: contain;
  margin-bottom: 14.32px;
  margin-top: 13px;
}
#enter .img2 {
  width: 45px;
  height: 56.33px;
  object-fit: contain;
  margin-bottom: 5.67px;
}
#enter .img3 {
  width: 51px;
  height: 51px;
  object-fit: contain;
  margin-bottom: 14px;
  margin-top: 2px;
}
#enter .img4 {
  width: 56px;
  height: 51.69px;
  object-fit: contain;
  margin-bottom: 14.85px;
}
.widthwrap {
  max-width: 545px;
  margin: auto;
}
.widthwrap .text {
  font-size: 16px;
  line-height: 29px;
}
.avatarbg .text {
  font-size: 12px;
  line-height: 19px;
}
.icons .text {
  font-size: 16px;
  line-height: 24px;
}
.teambox .head2 {
  padding-left: 19px;
}
.iconmbl img {
  display: inline-block;
  margin-left: auto;
}
.navbar-navmbl {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 110vh; */
  position: relative;
  /* background-color: #182030e6; */
  padding-top: 100px;
  z-index: 3;
}
.navmbl {
  z-index: 4;
}
.navbar-brand,
.navbar-toggler {
  z-index: 5;
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}
.navbar-toggler span {
  display: block;
  background-color: #ffffff;
  height: 1px;
  width: 25px;
  margin-top: 7px;
  margin-bottom: 7px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform 0.35s ease-in-out;
  -moz-transition: transform 0.35s ease-in-out;
  -o-transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0);
}
