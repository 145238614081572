@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;900&display=swap");

.head1mbl {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 53px;
  color: #ffffff;
  letter-spacing: 0.1em;
  padding-left: 20px;
  margin-bottom: -60px;
  z-index: 2;
  position: relative;
}
.head1 {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 90.66px;
  line-height: 67px;
  color: #ffffff;
  margin-bottom: 38px;
  letter-spacing: 0.09em;
}
.head2mbl {
  font-size: 46px;
  line-height: 55px;
}
.head11 {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 119.76px;
  line-height: 120px;
  margin-bottom: 38px;
  color: #ffffff;
  letter-spacing: 0.09em;
}
.head2 {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 50px;
  color: white;
  letter-spacing: 0.07em;
}
.herobgmbl {
  /* background-image: url(./images/hero.png);
  background-size: cover;
  background-position: center; */
  background-color: #0a1549;
}
.herobgmbl .row {
  min-height: 91vh;
}
.arrow {
  height: 40px;
}
.text {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #fffc;
  text-decoration: none;
}
.textmbl1 {
  font-size: 14px;
  font-family: "Inconsolata";

  line-height: 25px;
}
.scroll {
  letter-spacing: 0.4em;
}
.aboutbgmbl,
#value {
  /* background-image: url(./images/about_bg.png); */
  /* background-size: cover; */
  /* background-position: bottom center; */
  background-color: #15223b;
}
@media screen and (min-width: 576px) {
  .aboutbg .row {
    /* padding-top: 167px !important;
    padding-bottom: 167px !important; */
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-left {
    text-align: left !important;
  }
}
.row.full {
  /* padding-top: 100px; */
  /* padding-bottom: 167px; */
  min-height: 100vh;
}
.space {
  height: 140px;
}
.main.mbl {
  overflow-x: hidden;
  background-color: #07121a;
}
.avatarbg .row.full {
  min-height: 100vh;
}
.blueprintbg,
.upsidebg,
.tunnelbg,
.teambg,
.faqbg,
.footerbg {
  background-color: #07121a;
}
.blueprintbg .row,
.upsidebg .row,
.tunnelbg .row,
.teambg .row.full,
.faqbg .row {
  min-height: 100vh;
  /* padding: 150px 0 0; */
}
.icon {
  height: 55px;
}
.enterbg-2 {
  background-image: url(./images/enter.png);
  background-size: cover;
  background-position: center;
  mix-blend-mode: lighten;
  min-height: 50vh;
}
.btn-theme {
  padding: 10px 16px;
  color: white;
  /* background-color: #1d2652; */
  background-color: #182030;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: "Inconsolata";
}
.btn-theme.collapsed {
  background-color: #182030;
}
.imgfilter {
  mix-blend-mode: lighten;
}
.box {
  background-color: #182030;
  margin-top: 50%;
  position: relative;
  padding-top: 20% !important;
  min-height: 370px;
}
.boxmbl {
  background-color: #182030;
  margin-top: 72%;
  position: relative;
  padding-top: 0% !important;
  height: 267px;
}
.dronbig {
  bottom: 67%;
  height: 525px;
  width: 671px;
  object-fit: contain;
  left: 3.2vw;
  position: absolute;
  max-width: 100%;
  left: 39%;
  transform: translateX(-40%);
}
.dronbigmbl {
  margin-top: -415px;
  height: 389px;
  object-fit: cover;
  position: relative;
  margin-bottom: -46px;
  margin-left: -4px;
  z-index: 0;
}
.sign {
  max-width: 100%;
}
.faqbg {
  background-image: url(./images/faq.png);
  background-size: cover;
  background-position: center;
}
.accordion-item,
.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-item {
  border: 0;
  border-bottom: 1px solid #fffc;
}
.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.footer2bg {
  background-color: #07121a;
}
.head1.mid {
  font-size: 50px;
}
.footlogo {
  width: 81px;
  height: 81px;
}
.text-right {
  text-align: right;
}
.border {
  border: none !important;
  border-bottom: 1px solid #363f6b !important;
}
.nav-link {
  font-size: 18px;
  /* padding: 32px 27px !important; */
  padding: 32px 9px !important;
  color: #fffc !important;
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.09em;
}
@media screen and (min-width: 800px) {
  .nav-link {
    padding: 32px 10px !important;
  }
}
@media screen and (min-width: 850px) {
  .nav-link {
    padding: 32px 15px !important;
  }
}
@media screen and (min-width: 950px) {
  .nav-link {
    padding: 32px 20px !important;
  }
}
@media screen and (min-width: 950px) {
  .nav-link {
    padding: 32px 27px !important;
  }
}
.nav-link:hover {
  color: #fff !important;
}
.brick {
  max-width: 100%;
  width: 100%;
  /* padding-top: 150px; */
  margin-top: -20%;
  /* z-index: 99; */
  position: relative;
}
.dots {
  max-width: 100%;
}
.charactermbl {
  position: relative;
  width: 100%;
  height: 207px;
  overflow: hidden;
  z-index: 99;
}
.brickmbl {
  max-width: 100%;
  width: 100%;
  margin-top: -14%;
  position: relative;
}
.character {
  /* position: relative;
  width: 100%;
  height: 540px;
  overflow: hidden;
  z-index: 99; */

  position: relative;
  width: 100%;
  height: 422px;
  overflow: hidden;
  z-index: 99;
}
.character_imgmbl {
  position: absolute;
  object-fit: contain;
  height: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.character_img {
  /* position: absolute;
  object-fit: contain;
  height: 1080px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  position: absolute;
  object-fit: contain;
  height: 835px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* body,
#root {
  height: 100vh;
  overflow: hidden;
}
.main {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
} */
.scrollFix {
  /* scroll-snap-type: y mandatory;
  overflow: auto;
  height: 100vh; */
}
#blueprint,
#downside,
#tunnel,
#upside {
  /* display: inline-block;
  flex: 0 0 auto; */
  /* scroll-snap-align: center;
  height: 100vh !important; */
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.2s ease-in-out;
  z-index: 9999;
}
.nav-up {
  top: -87px;
}
/* .buttons img:hover {
  background-color: #363f6b;
} */

.button_color {
  width: 32px;
  height: 27px;
  margin: auto;
  margin-bottom: 8px;
}
.button_colormbl {
  width: 15px;
  height: 16px;
  margin: auto;
  margin-bottom: 7px;
}
.button_color.yellow {
  background-color: #ffc08b;
}
.button_color.tan {
  background-color: #ffa26e;
}
.button_color.brown {
  background-color: #c1633e;
}
.button_wrap_color {
  text-align: center;
  background-color: #182030;
  padding: 28px 0 16px;
}
.button_wrap_colormbl {
  padding: 18px 0 6px;
}
.button_wrap_color:active,
.highlight {
  background-color: #1d2652;
  cursor: pointer;
}

@media (hover: hover) {
  .button_wrap_color:hover {
    background-color: #1d2652;
    cursor: pointer;
  }
}

.button_wrap_color .text {
  font-size: 12px;
}
.buttonimg {
  height: 32px;
  object-fit: contain;
  margin: 10px 0 18px;
}
.buttonimgmbl {
  height: 25px;
  object-fit: contain;
  margin: 3px 0 25px;
}
.icons_btns img {
  width: 20px;
  height: 20px;
  border-right: 1px solid rgba(89, 101, 158, 0.2);
  padding-right: 5px;
  margin-right: 5px;
  object-fit: contain;
}
.icons_btns {
  color: white;
  font-size: 12px;
}
.icons_btns .button_wrap_color {
  text-align: left;
  padding: 10px 0 10px 15px;
}
.foothead {
  font-weight: 400;
  font-size: 31.4607px;
  line-height: 31px;
}
.teambox {
  width: 235px;
  max-width: 100%;
  height: 249px;
}
.teambox img {
  width: 195px;
  height: 195px;
  object-fit: cover;
}
.teambox:hover,
.focused,
.teamboxmbl:hover {
  background-color: #182030;
}
.borders {
  border-top: 1px solid #394867;
  border-bottom: 1px solid #394867;
}
.slide {
  min-height: 100vh;
}
.arrow {
  position: absolute;
  width: 30px;
  object-fit: contain;
  top: 50%;
  left: 10px;
  z-index: 999;
  cursor: pointer;
}
.arrow.right {
  left: auto;
  right: 10px;
}
#slides {
  position: relative;
}
@media (min-width: 1921px) {
  .d-xlc-block {
    display: block !important;
  }
  .py-xlc-5 {
    padding: 3rem !important;
  }
}
/* @media (min-height: )
.avatarfix{

} */
.cityhall {
  /* line-height: 91px; */
  /* margin-top: -85px; */
}
#enter .img1 {
  width: 88.69px;
  height: 46.68px;
  object-fit: contain;
  margin-bottom: 19.32px;
  margin-top: 13px;
}
#enter .img2 {
  width: 60px;
  height: 75px;
  object-fit: contain;
  margin-bottom: 5.67px;
}
#enter .img3 {
  width: 68px;
  height: 68px;
  object-fit: contain;
  margin-bottom: 14px;
  margin-top: 2px;
}
#enter .img4 {
  width: 74px;
  height: 68px;
  object-fit: contain;
  margin-bottom: 14.85px;
}
.widthwrap {
  max-width: 545px;
  margin: auto;
}
.widthwrap .text {
  font-size: 21.3333px;
  line-height: 180%;
}
.avatarbg .text {
  font-size: 16px;
  line-height: 26px;
}
.icons .text {
  font-size: 14px;
  line-height: 24px;
}
.teambox .head2 {
  padding-left: 19px;
}
.avatarbgmbl {
  background-color: #07121a;
}
.noletterspacing {
  letter-spacing: 0.01em;
}
.smalltext {
  font-size: 12px;
  line-height: 19px;
}
.slidesmbl {
  background-color: #07121a;
}
.upsidebgmbl {
  background-image: url(./images/upsidembl.png);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.tunnelbgmbl {
  background-image: url(./images/tunnelbgmbl.png);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.dotsmbl {
  width: 136px;
  margin-bottom: 20px;
}
.upsidebgmbl2 {
  background-image: url(./images/upsidembl2.png);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.head2teammbl {
  font-family: "Londrina Solid";
  font-style: normal;
  font-weight: 900;
  font-size: 15.153px;
  /* line-height: 42px; */
  padding: 10px;
  color: white;
  letter-spacing: 0.09em;
}
.blueprintimg {
  /* height: 350px; */
  height: 33vh;
  object-fit: cover;
  object-position: bottom;
}
.container {
  max-width: 1920px !important;
}
.mt-n5 {
  margin-bottom: 169px;
  margin-top: -169px;
}
#avatar {
  /* top: -100px; */
}
.bannervideo {
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  object-fit: cover;
  object-position: 40% bottom;
  z-index: -1;
}
.bannervideombl {
  width: 100vw;
  height: 91vh;
  /* height: 50vh; */
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  object-fit: cover;
  object-position: 35% center;
  z-index: -1;
}
.slick-slider {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-touch-callout: auto;
  -khtml-user-select: auto;
  -ms-touch-action: auto;
  touch-action: auto;
  -webkit-tap-highlight-color: transparent;
}
.headingD {
  font-size: 50px !important;
  line-height: 67px !important;
}
/* .subheadingD {
  font-size: 24px !important;
  line-height: 50px !important;
} */
.textD {
  font-size: 15px !important;
  line-height: 22px !important;
}
.navmbl .nav-link {
  padding: 15px !important;
  height: 55px;
}
.lines .line {
  height: 16px;
  padding: 6px 0;
  cursor: pointer;
}

.lines .line span {
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  display: block;
}
.line.active span {
  background-color: rgba(255, 255, 255);
}
.op {
  opacity: 0.7;
}
.teambox .head2 {
  letter-spacing: 0.09em;
}
.slick-track::after,
.slick-track::before {
  display: table;
  content: none !important;
}
.accordion-body.text {
  font-size: 1rem;
}
.button_wrap_color .text {
  /* font-size: 15px !important;
  line-height: 22px !important; */
  font-size: 14px !important;
  line-height: 25px !important;
}
.nav-link img {
  height: 35px;
}
/* @media (hover: hover) {
  .btn-theme:hover {
    background-color: #1d2652;
    cursor: pointer;
  }
}
.btn-theme:active {
  background-color: #1d2652;
  cursor: pointer;
} */
.footer2bg a {
  text-decoration: none;
}
#slides .accordion-body.text {
  font-size: 14px;
  line-height: 25px;
}
#faq .accordion-header button {
  font-size: 14px;
  line-height: 25px;
  color: #fffc !important;
}
.accordion-body.text {
  font-size: 14px;
  line-height: 25px;
}
/* .btn.btn-theme.text-white:hover,
.btn.btn-theme.text-white:active,
.btn.btn-theme.text-white:focus  */
.btn.btn-theme.text-white[aria-expanded="true"] {
  background-color: #1d2652;
  box-shadow: none !important;
}

#aboutmbl {
  display: block;
  position: absolute;
  top: 91px;
  left: 0;
}
#team {
  overflow-x: hidden;
}

@media screen and (max-width: 776px) {
  .menubar {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: #182030e6 !important;
  }
  .characterwrap {
    padding-left: 0 !important;
  }
}
